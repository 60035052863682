.App {
  text-align: center;
  background-color: #222222;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

h1 {
  font-size: 2em;
  margin: 0;
}

h2 {
  font-size: 0.5em;
  bottom: 0;
}

sup {
  font-size: 0.5em;
}

button:focus {
  border: 0px solid transparent;
}

svg {
  color: #444444;
  transition: color 0.5s;
}

svg:hover {
  color: #666666;
}

.App a {
  text-decoration: none;
  color: #333333;
  transition: color 0.5s;
}

.App a:hover {
  color: #666666;
}

.App-body {
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  margin: 0.5em;
  width: min(640px, min(90vw, 90vh));
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
}

.TooltipButton {
  display: flex;
  width: 3em;
  height: 3em;
  border: 0px transparent;
  border-radius: 100%;
  padding: 0;
  background-color: #2a2a2a;
}

.Palette-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;;
}

.ExportButton {
  margin-top: auto;
}

.ExportOverlay {
  position: fixed;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  background: #000000aa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  z-index: 100;
}

.Export {
  width: min(640px, min(90vw, 90vh));
  height: min(640px, min(90vw, 90vh));
  border: 8px solid #222222;
}

.Palette-window {
  width: min(640px, min(90vw, 90vh));
  height: min(640px, min(90vw, 90vh));
  position: relative;
}

.PaletteView {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid transparent;
  border-radius: 16px;
  z-index: 0;
}

.TextView {
  width: 100%;
  height: 100%;
  position:absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

.ColorButtons {
  display: flex;
  height: max(32px, min(64px, min(10vw, 10vh)));
  width: min(640px, min(90vw, 90vh));
  padding: 16px;
  z-index: 10;
}

.ColorButton {
  height: 100%;
  width: 25%;
}

.ColorButton.tl {
  border-radius: 16px 0px 0px 16px;
}

.ColorButton.bl {
  border-radius: 0px 16px 16px 0px;
}

.ColorPicker {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.ColorPicker .Bubble {
  margin-bottom: -5%;
}

.Swatch {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;
}

.SwatchButton {
  flex: 1;
  aspect-ratio: 1 / 1;
  border-radius: 16px;
}

.HexInput {
  background-color: #444444;
  border-radius: 16px;
  padding: 8px;
  outline: none;
  border: none;
  text-transform: uppercase;
  color: #a4a4a4;
  font-size: calc(10px + 1vmin);
  text-align: center;
}

.MuiSnackbarContent-message {
  margin: auto;
  color: #a4a4a4;
}

.MuiPaper-root {
  background-color: #333333;
}

.Tooltip {
  position: relative;
  align-items: center;
  justify-content: center;
}

.Bubble {
  background-color: #333333;
  color: #a4a4a4;
  text-align: center;
  padding: 8px;
  border-radius: 16px;

  /* Position the bubble text */
  position: absolute;
  z-index: 10;
  bottom: 125%;
}

/* Tooltip arrow */
.Bubble::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
}

.Tooltip .Bubble {
  visibility: hidden;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.Tooltip:hover .Bubble {
  visibility: visible;
  opacity: 1;
}

.Tooltip:hover:disabled .Bubble {
  color: #7c7c7c;
  text-decoration: line-through;
}

.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}